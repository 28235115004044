import React from "react";
import { Button, Card } from "react-bootstrap";


const CustomCard = ({src,content,btnTitle,variant,bgColor,description}) => {
  return (
    <Card style={{ width: "30rem",  }} className="box">
      <Card.Body className="">
        <div className="d-flex flex-column justify-content-center align-items-center">
          <div className="img-container">
            <img src={src} className="h-100 w-100"/>
          </div>
          <div className="pt-3 text-center">
            <p className="fw-bold">{content}</p>
            <p>{description}</p>
            {/* <Button variant={variant}>{btnTitle}</Button> */}
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default CustomCard;
