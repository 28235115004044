import { UnsubRelator } from "./endPoints";

const PostApiData = (endPoint, payLoad) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
  
    var raw = JSON.stringify(payLoad);
  
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
  
    return fetch(endPoint, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        return result;
      })
      .catch((error) => {
        console.log("error", error);
        return null;
      });
  };

  
  export const UnsubRealtorApi = (data) => {
    return PostApiData(UnsubRelator, data);
  };