import React, { useEffect, useState } from "react";
import "../assets/css/style.css";
import { Button, Card } from "react-bootstrap";
import CustomCard from "../Components/CustomCard";
// import okImg from "../assets/images/ok.png";
import successImg from "../assets/images/ok-icon.png";
import warnImg from "../assets/images/warning_icon.png";
import failureImg from "../assets/images/failure_img.png";
import { useNavigate, useParams } from "react-router-dom";
import { UnsubRealtorApi } from "../utils/ApiCalls";
const UnsubRealtor = ({ refID }) => {
  const [Data, setData] = useState({});
  const [LoaderState, setLoaderState] = useState(false);
  const navigate = useNavigate();
  // const {id} = useParams();
  const id = refID;
  console.log("ID", refID);
  useEffect(() => {
    const numericId = Number(refID);
    if (!isNaN(numericId)) {
      handleUnSubRelator(numericId);
    } else {
      navigate("/");
    }
  }, [id]);
  const handleUnSubRelator = async () => {
    setLoaderState(true);
    const idobj = {
      realtorID: id,
    };
    try {
      const UnsubRealtorApiResp = await UnsubRealtorApi(idobj);
      setData(UnsubRealtorApiResp.Details);
      setLoaderState(false);
    } catch {
      setData("Warning");
      setLoaderState(false);
    }
  };
  return (
    <div className="container">
      {Data == "Success" ? (
        <CustomCard
          btnTitle={"Success"}
          content={"Successfully Unsubscribed"}
          variant={"success"}
          src={successImg}
          description={"Successfully unsubscribed from Daily Rates Email."}
        />
      ) : Data === "Failure" ? (
        <CustomCard
          btnTitle={"Try Again"}
          content={"Failure in Unsubscribing"}
          variant={"danger"}
          src={failureImg}
          description={
            "Something went wrong while Unsubscribing from Daily Rates Emails."
          }
        />
      ) : Data === "Warning" ? (
        <CustomCard
          btnTitle={"ok"}
          content={"Seomthing Went Wrong"}
          variant={"warn"}
          src={warnImg}
          description={
            "Something went wrong while Unsubscribing from Daily Rates Emails."
          }
        />
      ) : (
        ""
      )}

      {LoaderState?
      <div class="loader"></div>
      :""}


    </div>
  );
};

export default UnsubRealtor;
