import React, { useEffect, useState } from 'react'
import UnsubRealtor from './UnsubRealtor'

const Home = () => {
  const [Unsubscribe, setUnsubscribe]=useState(false)
  const [ref_id, setref_id] = useState("")
useEffect(()=>{
  const urlParams = new URLSearchParams(window.location.search);
  const refID = urlParams.get('refID');
  console.log("REFId",refID)
if(refID){
  setref_id(refID)
setUnsubscribe(true)
}
else{
  window.location.replace("https://houzd.com");

}

},[])

  return (<>
    {Unsubscribe?
  <UnsubRealtor refID={ref_id}  />
  :
    <div className='h-100 w-100 bg-dark'>
      
    </div>
    }
    </>
  )
}

export default Home
