import UnsubRealtor from './pages/UnsubRealtor';
import AppRoutes from './routes/appRoutes';

function App() {
  return (
    <div className="App">
    <AppRoutes/>
    </div>
  );
}

export default App;
