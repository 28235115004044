import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "../pages/Home";
import UnsubRealtor from "../pages/UnsubRealtor";

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Home />} path="/" />
        {/* <Route element={<UnsubRealtor />} path="/:id" /> */}
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
